import React from "react"
import LayoutNoHeader from "../components/layoutWithoutHeader"
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import LoginForm from "../components/Organisms/LoginForm/LoginForm"
import config from "../utils/siteConfig"

export default function Signin({ data }) {
    debugger;
    const page = data.allContentfulZSignin.edges[0].node
    const options = {
        renderNode: {
            [BLOCKS.HEADING_1]: (node, children) => (
                <h1 className="heading1">{children}</h1>
            ),
            [BLOCKS.HEADING_2]: (node, children) => (
                <h2 className="heading2">{children}</h2>
            ),
            [BLOCKS.HEADING_3]: (node, children) => (
                <h3 className="heading3">{children}</h3>
            ),
            [BLOCKS.HEADING_4]: (node, children) => (
                <h4 className="heading4">{children}</h4>
            ),
            [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
                <img src={`https:${node.data.target.fields.file["en-US"].url}`} />
            ),
            [BLOCKS.PARAGRAPH]: (node, children) => (
                <p className="copy">{children}</p>
            ),

        },
        renderMark: {},
        renderText: (text) =>
            text
                .replace(/\u2028/g, "")
                .split("\n")
                .flatMap((text, i) => [i > 0 && <br />, text])
    }

    const {
        id,
        metaData,
        lnFBtn,
        lnFConcent,
        lnFHd,
        lnFTitle1,
        lnFTitle2,
        lnFTxt,
        lnFTxt2,
        lnHRtxt2,
        lnHRtxt
    } = page

    return (
        <LayoutNoHeader>
            <head>
                <title>{metaData.title}</title>
                <meta charset="utf-8" />
                <meta name="robots" content="noindex" />
            </head>
            <section>
                <div className="login-container">
                    <div className="login-subcontainer">
                        <div className="row pt-5 pl-3 pb-3">
                            <a href={config.navigationURL_home}>
                                <img
                                    src={require("../images/Logo/wunderloop_logo.svg")}
                                    style={{ width: "80%" }}
                                    alt="Wunderloop logo"
                                />
                                </a>
                        </div>
                    </div>
                    <div className="row justify-content-center align-items-center">
                        <LoginForm {...page} />
                    </div>
                    <div className="login-subcontainer">
                        <div className="row flex-nowrap pl-3 pt-3">
                            <h4 className="h4-login">
                                {documentToReactComponents(lnHRtxt.json, options)}
                            </h4>
                        </div>
                        <div className="row flex-nowrap pl-3 pt-3">
                            <h4 className="h4-login">
                                {documentToReactComponents(lnHRtxt2.json, options)}
                            </h4>
                        </div>
                    </div>
                </div>
            </section>

        </LayoutNoHeader>
    );
}


export const query = graphql`
query($locale: String!) {
    allContentfulZSignin(
        filter: {
        node_locale: { eq: $locale }
    }) {
        edges {
            node {
                id
                metaData {
                    slug
                    title
                    description
                    keywords {
                        keywords
                    }
                }
                lnFBtn
                lnFConcent
                lnFHd
                lnFTitle1
                lnFTitle2
                lnFTxt
                lnFTxt2
                lnHRtxt {
                    json
                }
                lnHRtxt2 {
                    json
                }
            }
        }
    }
}
`