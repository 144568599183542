import React from "react"
import layoutStyles from "./layout.module.css"
// import CookieConsent, { Cookies } from "react-cookie-consent";
import CookieConsent from "./Molecules/CookieConsent/CookieConsent"

export default function LayoutNoHeader(props) {
  debugger
  return (
    <div className={layoutStyles.layout}>
      {props.children}
      <CookieConsent />
    </div>
  )
}