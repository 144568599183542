import React from "react"
import { navigateTo } from "gatsby-link"
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import Button from "../../Atoms/Button/Button"
import loginFormStyles from "./LoginForm.module.scss"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default class loginForm extends React.Component {
  constructor(props) {
    super(props)
    debugger
    this.props = props
    this.state = {}
  }

  options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => (
        <h1 className="heading1">{children}</h1>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <h2 className="heading2">{children}</h2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <h3 className="heading3">{children}</h3>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <h4 className="heading4">{children}</h4>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
        <img src={`https:${node.data.target.fields.file["en-US"].url}`} />
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="copy">{children}</p>
      ),
    },
    renderMark: {},
    renderText: text =>
      text
        .replace(/\u2028/g, "")
        .split("\n")
        .flatMap((text, i) => [i > 0 && <br />, text]),
  }

  debugger
  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    Promise.all([
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": form.getAttribute("name"),
          ...this.state,
        }),
      }),
      fetch("https://getform.io/f/f8237525-74ff-4231-adc6-7a47da409cd5", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": form.getAttribute("name"),
          ...this.state,
        }),
      }),
    ])
      .then(() => navigateTo("/thankyou"))
      .catch(error => alert(error))
  }

  render() {
    return (
      <div className={loginFormStyles.loginForm}>
        <form
          name="login"
          method="post"
          // action="https://getform.io/f/f8237525-74ff-4231-adc6-7a47da409cd5"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={this.handleSubmit}
        >
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input type="hidden" name="form-name" value="login" />
          <p hidden>
            <label>
              Don’t fill this out:{" "}
              <input name="bot-field" onChange={this.handleChange} />
            </label>
          </p>
          <h4> Sign in to your account </h4>
          <div className="block pt-3">
            <label for="email">{this.props.lnFTitle1}</label>
            <input
              type="email"
              name="email"
              inputMode="email"
              onChange={this.handleChange}
              pattern="^[\w]{1,}[\w.+-]{0,}@[\w-]{2,}([.][a-zA-Z]{2,}|[.][\w-]{2,}[.][a-zA-Z]{2,})$"
              required
              title="Enter correct e-mail format"
            />
          </div>
          <div className="block pt-3">
            <label for="password">{this.props.lnFTitle2}</label>
            <p className="float-right">
              <a href="" className="btn-mobile">
                {this.props.lnFTxt2}
              </a>
            </p>
            <input
              type="password"
              name="password"
              id="password"
              inputMode="password"
              onChange={this.handleChange}
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
              title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
              required
            />
          </div>
          <div className="row justify-content-start pl-3 pt-3">
            <p>
              <input
                type="checkbox"
                name="agreed"
                onChange={this.handleChange}
                required
              />
              {this.props.lnFConcent}
            </p>
          </div>
          <div className="row justify-content-center pt-3">
            <a href="" className="btn-mobile">
              <button
                className={loginFormStyles.btn}
                type="submit"
                name="submit"
              >
                {this.props.lnFBtn}
              </button>
            </a>
          </div>
        </form>
      </div>
    )
  }
}
